import React from "react";
import {
  Box,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import { FancyHr } from "../FancyHr";

import HeroPlanYourVisit from "../../images/hero-plan-your-visit.jpg";

const PlanYourVisit = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Box
        component="img"
        src={HeroPlanYourVisit}
        alt="image of racc"
        sx={{ width: `100%` }}
      />
      <Box py={isSmall ? 2 : 13} px={isSmall ? 2 : "initial"}>
        <Grid container>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="center">
              <Typography variant="h4" align="center">
                The Museum
              </Typography>
            </Box>
            <FancyHr center />

            <Typography paragraph align="center">
              Richard Allen Cultural Center and Museum features artifacts from
              the Buffalo Soldiers and ruins of Bethel AME Church Underground
              Railroad Site along with memorabilia belonging to notable
              African-American figures such as General Colin Powell. We also
              showcase bronze statues of Buffalo Soldiers and an exclusive Black
              Dignity photo gallery, known as the Everhard Collection, dating
              back to the late 1800s.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Box>
      <Box
        py={isSmall ? 2 : 13}
        px={isSmall ? 2 : "initial"}
        style={{ backgroundColor: `#EFEFEF` }}
      >
        <Grid container>
          <Grid item xs={12} md={3} />
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="center">
              <Typography variant="h4" align="center">
                Our Mission
              </Typography>
            </Box>
            <FancyHr center />
            <Typography paragraph align="center">
              Our mission and purpose is to educate the public about the rich
              history of African- Americans and their contributions to every
              phase of American life. We promote a greater awareness of
              African-American heritage through research and cultural activities
              that enhance interracial understanding.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} />
        </Grid>
      </Box>
      <Box
        py={isSmall ? 2 : 13}
        px={isSmall ? 2 : "initial"}
        style={{ backgroundColor: `rgba(37, 40, 58, 1)` }}
      >
        <Grid container>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="center">
              <Typography variant="h4" align="center" color="textSecondary">
                Programs
              </Typography>
            </Box>
            <FancyHr center />
            <Typography paragraph align="center" color="textSecondary">
              Educational programs are also offered through museum exhibits,
              tours, research opportunities and traveling exhibits for the
              general public. We also have a year-round tutoring program
              available for any student who needs the service. We have helped
              over 350 children improve their academic skills.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PlanYourVisit;
